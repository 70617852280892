var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"ml-3 mt-2",attrs:{"elevation":hover ? 24 : 2}},[_c('v-toolbar',{staticClass:"white--text",attrs:{"color":"indigo","dense":"","flat":""}},[_c('v-toolbar-title',[_vm._v("Controllo Qualità "),_c('v-icon',{staticClass:"mt-n1",attrs:{"color":"white"}},[_vm._v("mdi-file-check-outline")])],1),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.gotoDashboard}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-keyboard-backspace")])],1)]}}],null,true)},[_c('span',[_vm._v("Vai alla Dashboard")])])],1),_c('v-layout',{staticClass:"justify-center"},[_c('v-card-actions',{attrs:{"colored-border":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"primary--text",staticStyle:{"font-size":"25px","font-weight":"700"},attrs:{"elevation":hover ? 0 : 5,"medium":""},on:{"click":_vm.clickFaseStandard}},[_vm._v(" Benestare a Produrre ")])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"elevation-5 primary--text",staticStyle:{"font-size":"25px","font-weight":"700"},attrs:{"elevation":hover ? 0 : 5,"medium":""},on:{"click":_vm.clickPianoControllo}},[_vm._v(" Piano Controllo ")])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"primary--text",staticStyle:{"font-size":"25px","font-weight":"700"},attrs:{"elevation":hover ? 0 : 5,"medium":""},on:{"click":_vm.clickAutoControllo}},[_vm._v(" Ciclo Autocontrollo ")])]}}],null,true)})],1)],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }