<template>
  <div style="width: 100%">
    <!-- Div contenente lo sfondo (fisso) di questa videata -->
    <div class="sfondo" style="position: fixed; left: 0px; top: 0px"></div>
    <v-row v-if="menu_visible">
      <v-col cols="0" md="6"></v-col>
      <v-col class="pr-10" cols="12" md="6">
        <!-- Menu di selezione -->
        <Menu
          style="display: inline-block; width: 100%"
          @piano_controllo="(piano_controllo = true), (menu_visible = false)"
          @ciclo_auto_controllo="(ciclo_auto_controllo = true), (menu_visible = false)"
          @benestare="(benestare = true), (menu_visible = false)"
          v-if="menu_visible"
        />
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="10" class="mt-4">
        <PianoControllo
          @click_chiudi="(piano_controllo = false), (menu_visible = true)"
          v-if="piano_controllo"
        />
        />
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="11" class="mt-2">
        <CicloAutoControllo
          @click_chiudi="(ciclo_auto_controllo = false), (menu_visible = true)"
          v-if="ciclo_auto_controllo"
        />
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="10" class="mt-2">
        <Benestare
          @click_chiudi="(benestare = false), (menu_visible = true)"
          v-if="benestare"
        />
      </v-col>
    </v-row>
    <v-overlay :value="caricamento_dati">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import Menu from "./Menu.vue";
import Benestare from "./Benestare/Benestare";
import PianoControllo from "./PianoControlo/PianoControllo";
import CicloAutoControllo from "./CicloAutoControllo/CicloAutocontrollo";
export default {
  name: "Avanzamento",
  components: {
    Menu,
    Benestare,
    PianoControllo,
    CicloAutoControllo,
  },
  data() {
    return {
      // Variabili d'appoggio
      // Modal
      caricamento_dati: false,
      piano_controllo: false,
      benestare: false,
      ciclo_auto_controllo: false,
      menu_visible: true,
    };
    // Array
  },
  methods: {
    togglePianoControllo() {
      this.ciclo_auto_controllo = false;
      this.piano_controllo = true;
      this.benestare = false;
    },
  },
  mounted() {
    if (this.$route.params.comp == 1) {
      this.piano_controllo = true;
      this.menu_visible = false;
    }
    if (this.$route.params.comp == 2) {
      this.ciclo_auto_controllo = true;
      this.menu_visible = false;
    }
    if (this.$route.params.comp == 3) {
      this.benestare = true;
      this.menu_visible = false;
    }
  },
};
</script>
<style scoped>
.sfondo {
  width: 100vw !important;
  background-image: url("../../assets/Ind.jpg");
  background-color: #cccccc; /* Used if the image is unavailable */
  height: 100vh !important; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  overflow: hidden !important; /* Resize the background image to cover the entire container */
}
</style>
