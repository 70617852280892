<template>
  <div>
    <Qualita />
  </div>
</template>

<script>
import Qualita from "../../../components/Qualita/QualitaMenu";
export default {
  components: {
    Qualita,
  },
};
</script>
