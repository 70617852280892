<template>
  <v-hover>
    <template v-slot="{ hover }">
      <v-card class="ml-3 mt-2" :elevation="hover ? 24 : 2">
        <v-toolbar class="white--text" color="indigo" dense flat>
          <v-toolbar-title
            >Controllo Qualità
            <v-icon color="white" class="mt-n1">mdi-file-check-outline</v-icon>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="gotoDashboard" v-bind="attrs" v-on="on" small>
                <v-icon color="error">mdi-keyboard-backspace</v-icon>
              </v-btn>
            </template>
            <span>Vai alla Dashboard</span>
          </v-tooltip>
        </v-toolbar>
        <v-layout class="justify-center">
          <v-card-actions colored-border>
            <v-row>
              <v-col cols="12">
                <v-hover>
                  <template v-slot="{ hover }">
                    <v-btn
                      :elevation="hover ? 0 : 5"
                      class="primary--text"
                      style="font-size: 25px; font-weight: 700"
                      @click="clickFaseStandard"
                      medium
                    >
                      Benestare a Produrre
                    </v-btn>
                  </template>
                </v-hover>
              </v-col>
              <v-col class="" cols="12">
                <v-hover>
                  <template v-slot="{ hover }">
                    <v-btn
                      :elevation="hover ? 0 : 5"
                      class="elevation-5 primary--text"
                      @click="clickPianoControllo"
                      style="font-size: 25px; font-weight: 700"
                      medium
                    >
                      Piano Controllo
                    </v-btn>
                  </template>
                </v-hover>
              </v-col>
              <v-col cols="12">
                <v-hover>
                  <template v-slot="{ hover }">
                    <v-btn
                      :elevation="hover ? 0 : 5"
                      class="primary--text"
                      style="font-size: 25px; font-weight: 700"
                      @click="clickAutoControllo"
                      medium
                    >
                      Ciclo Autocontrollo
                    </v-btn>
                  </template>
                </v-hover>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-layout>
      </v-card>
    </template>
  </v-hover>
</template>
<script>
export default {
  name: "Menu",
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {
    // Go back Radial Menu
    gotoDashboard() {
      this.$router.push("/home/production");
    },
    clickAutoControllo() {
      this.$emit("ciclo_auto_controllo");
    },
    clickPianoControllo() {
      this.$emit("piano_controllo");
    },
    clickFaseStandard() {
      this.$emit("benestare");
    },
  },
};
</script>
